$(function() {
 	// ===================================================================
	// Global
	// ===================================================================
	let controller = new ScrollMagic.Controller();
	const $root = $('html, body');
	let resized;
	let _windowWidth = $(window).outerWidth();

	function resizeWidthOnly() {
		let currentWindowWidth = $(window).outerWidth();
		if (_windowWidth !== currentWindowWidth) {
			_windowWidth = currentWindowWidth;
			return _windowWidth;
		} else {
			return false;
		}
	}

	function resizedw() {
		if (resizeWidthOnly() !== false) {
			hideAllNavElements();
		}
	}

	window.onresize = function () {
		clearTimeout(resized);
		resized = setTimeout(resizedw, 100);
	}

 	// ===================================================================
	// Header
	// ===================================================================
    new ScrollMagic.Scene({
        triggerElement: ".rsd-hero",
        triggerHook: 0,
        offset: 1
    })
	.addTo(controller)
	.on("enter", function () {
		$(".rsd-header").addClass("rsd-header-sticky");
	})
	.on("leave", function () {
		$(".rsd-header").removeClass("rsd-header-sticky");
	});

	// ===================================================================
	// Parallax
	// ===================================================================
	parallax();
	function parallax() {
		//Parallax copy
		[].slice.apply(document.querySelectorAll(".parallax-copy")).forEach(function (el) {
			let opts = {triggerElement: el, triggerHook: "onEnter", duration: "125%"},
				scene = new ScrollMagic.Scene(opts).setTween(el, {x: "-25%", ease: Linear.easeNone}).addTo(controller);
		});

		//Parallax rotated items
		[].slice.apply(document.querySelectorAll(".parallax-rotated")).forEach(function (el) {
			let opts = {triggerElement: el, triggerHook: "onEnter", duration: "150%"},
				scene = new ScrollMagic.Scene(opts).setTween(el, {y: "-50%", ease: Linear.easeNone}).addTo(controller);
		});

		//Parallax background
		[].slice.apply(document.querySelectorAll(".parallax-parent")).forEach(function (el) {
			let opts = {triggerElement: el, triggerHook: "onEnter", duration: "175%"},
				scene = new ScrollMagic.Scene(opts).setTween($(el).find(".parallax-parent-img"), {
					y: "80%",
					ease: Linear.easeNone
				}).addTo(controller);

			$(el).fadeIn();
		});

		//fade up
		[].slice.apply(document.querySelectorAll(".fade-up")).forEach(function (el) {
			let opts = {triggerElement: el, triggerHook: 1, offset: 20},
				scene = new ScrollMagic.Scene(opts).addTo(controller);
			scene.on("start end", function (event) {
				el.classList.add("in-view");
			});
		});

		[].slice.apply(document.querySelectorAll(".fade-up-children")).forEach(function (el) {
			let opts = {triggerElement: el, triggerHook: 1, offset: 20},
				scene = new ScrollMagic.Scene(opts).addTo(controller);
			scene.on("start end", function (event) {
				el.classList.add("in-view");
			});
		});
	}

	// ===================================================================
	// Smooth Scrolling
	// ===================================================================
	smoothScroll();
	function smoothScroll() {
		$(document).on("click", 'a[href^="#"]', function () {
			if ($.attr(this, 'href').length > 1 && !$.attr(this, 'rel')) {
				$root.animate({
					scrollTop: $($.attr(this, 'href')).offset().top - $(".rsd-header").outerHeight() + 10
				}, 300);

				hideNavMenu();
			}
			return false;
		});
	}

	// ===================================================================
	// Sticky Header
	// ===================================================================
	$(window).scroll(function () {
		setStickyHeader();
	});

	setStickyHeader();
	function setStickyHeader() {
		if ($(document).scrollTop() > 0) {
			$(".vip-header").addClass("active");
		} else {
			$(".vip-header").removeClass("active");
		}
	}

	// ===================================================================
	// Menu
	// ===================================================================

	//Hamburger menu click event
	$("#js-hamburger").click(function (e) {
		e.preventDefault();
		$("body").addClass("no-scroll");
		$(this).toggleClass("active");
		$(".rsd-header").toggleClass("active");

		if (!$(".rsd-header").hasClass("active")) {
			$("body").removeClass("no-scroll");
		}
	});

	//Hide Menu
	function hideNavMenu() {
		$(".rsd-header-hamburger, .rsd-header").removeClass("active");
		$("body").removeClass("no-scroll");
	}

	function hideAllNavElements() {
		$(".rsd-header").removeClass("active");
		$("rsd-header__col-hamburger").removeClass("rsd-header__col-hamburger-open").addClass("rsd-header__col-hamburger-closed");
		$("body").removeClass("no-scroll");
	}

	// ===================================================================
	// Navigation Triggers
	// ===================================================================
	navTriggers();
	function navTriggers() {
		let navTriggerArray = [];

		$(".nav-trigger").each(function (i, obj) {
			navTriggerArray[i] = new ScrollMagic.Scene({
				triggerElement: obj,
				triggerHook: 0,
				duration: $(obj).outerHeight(),
				offset: -$('.rsd-header').outerHeight()
			})
			.addTo(controller);

			navTriggerArray[i]
			.on("enter", function (event) {
				$(".rsd-header-nav .link-inherit").removeClass("active");
				$(".js-nav-" + i).addClass("active");
			})
			.on("leave", function (event) {
				$(".rsd-header-nav .link-inherit").removeClass("active");
			});
		});
	}

	// ===================================================================
	// CountUp JS for Status Section
	// ===================================================================
	statusCountUp();
	function statusCountUp() {
		$(".rsd-stats h2 span").each(function (i, obj) {
			new countUp.CountUp(obj, $(obj).data("countto"), {enableScrollSpy: true, scrollSpyOnce: true})
		});
	}


	// ===================================================================
	// Vimeo Videos - GTM Tracking
	// ===================================================================
	var iframes = document.querySelectorAll('iframe');
	var vimeoPlayers = [];
	iframes.forEach(function(el,index){
		var iframeURL = el.getAttribute('src');
		if (iframeURL.includes('vimeo') && !iframeURL.includes('background=1')){
			vimeoPlayers[index] = new Vimeo.Player(el);
			vimeoPlayers[index].on('play', function(){
					vimeoPlayers[index].getVideoId().then(function(id){
						// GTM: dataLayer Push
						pushGTM('play-vimeo',{
							'action' : 'Play',
							'video_id' : id
						});
					}).catch(function(error){
						// an error occurred
					});
			});
		}
	});

	// ===================================================================
	// Contact Us
	// ===================================================================

	//Selectmenu for normal form
	$("#js-contact-us-selectmenu").selectmenu({
		classes: {
			"ui-selectmenu-button": "placeholder"
		},
		change: function (event, ui) {
			let _thisVal = $(this).val();

			if (_thisVal === "Advertiser seeking agency partnership") {
				thankYouMessage("1");
				showNormalFormSelectMenuOptions(".rsd-contact-step-2-1", ".rsd-contact-step-2-2, .rsd-contact-step-2-3");
			} else if (_thisVal === "Media vendor seeking partnership") {
				thankYouMessage("2");
				showNormalFormSelectMenuOptions(".rsd-contact-step-2-2", ".rsd-contact-step-2-1, .rsd-contact-step-2-3");
			} else if (_thisVal === "Other") {
				thankYouMessage("3");
				showNormalFormSelectMenuOptions(".rsd-contact-step-2-3", ".rsd-contact-step-2-1, .rsd-contact-step-2-2");
			}

			$("#js-contact-us-selectmenu-button").removeClass("placeholder");
			$(".rsd-contact .rsd-checkbox input[type=checkbox]").prop( "checked", false );
		}
	});

	function thankYouMessage(_id) {
		let _thankYouCopy = $(".rsd-contact-step-6").find("p");

		if (_id === "1") {
			_thankYouCopy.html("Someone from Rainstorm Direct will contact you shortly.");
		} else if (_id === "2") {
			_thankYouCopy.html("We'll keep your information on file and will reach out if there's an opportunity to work together.");
		} else if (_id === "3") {
			_thankYouCopy.html("We'll follow up with you about your question or comment.");
		}
	}

	//Selectmenu hide/show section for normal form
	function showNormalFormSelectMenuOptions(_show, _hide) {
		$(_hide).slideUp(function () {
			$(_show).slideDown();
		});
	}

	let _currentStep;
	let _nextStep;

	$(".js-contact-next").click(function (e) {
		e.preventDefault();
		let _this = $(this);
		_currentStep = _this.closest(".rsd-contact-step")
		_nextStep = _this.attr("data-step")

		contactFormValidation();
	});

	$(".js-contact-prev").click(function (e) {
		e.preventDefault();
		let _this = $(this);
		_currentStep = _this.closest(".rsd-contact-step")
		_nextStep = _this.attr("data-step");
		showDesiredStep();
	});

	//Validation
	function contactFormValidation() {
		let _form = $("#rsd-contact-form-normal");

		let servicesRule = {
			required: {
				depends: function(element) {
					return $('.rsd-contact-step-2-1-checkbox:checked').length === 0;
				}
			},
		};

		let mediaRule = {
			required: {
				depends: function(element) {
					return $('.rsd-contact-step-2-2-checkbox:checked').length === 0;
				}
			},
		};

		$(".rsd-contact-step-2-1-checkbox, .rsd-contact-step-2-2-checkbox").change(function() {
			_form.valid();
		});

		_form.validate({
			errorPlacement: function (label, element){
				if (element.is(":checkbox") || element.is(":radio")) {
					// console.log(element[0].name);
					// we only want to show 1 for the checkboxes but we have an error trying to show for each checkbox
					if (element[0].name !== "services2" &&
						element[0].name !== "services3" &&
						element[0].name !== "services4" &&
						element[0].name !== "media2" &&
						element[0].name !== "media3" &&
						element[0].name !== "media4" &&
						element[0].name !== "media5" &&
						element[0].name !== "media6" &&
						element[0].name !== "media7") {
						let _container = $(element).closest(".rsd-contact-step-checkbox-container");
						_container.addClass("error");
						label.appendTo(_container);
					}
				} else if (element.is("select")) {
					$(element).parent().addClass("error");
					label.insertAfter(element.parent().find(".ui-selectmenu-button"));
				} else {
					$(element).parent().addClass("error");
					label.insertAfter(element);
				}
			},
			success: function (label, element) {
				$(element).parent().removeClass("error");
				label.remove();
			},
			rules: {
				"email": {
					email: true
				},
				"services1": servicesRule,
				"services2": servicesRule,
				"services3": servicesRule,
				"services4": servicesRule,
				"media1": mediaRule,
				"media2": mediaRule,
				"media3": mediaRule,
				"media4": mediaRule,
				"media5": mediaRule,
				"media6": mediaRule,
				"media7": mediaRule
			},
			messages: {
				"name-first": "Oops! You skipped me",
				"name-last": "Oops! You skipped me",
				"company-name": "Oops! You skipped me.",
				"company-title": "Oops! You skipped me.",
				"email": "This is awkward. That email's invalid.",
				"phone": "Oops! You skipped me.",
				"regarding": "Oops, you skipped me! Please select one.",
				"messageServices": "Oops! You skipped me.",
				"messageMedia": "Oops! You skipped me.",
				"messageGeneral": "Oops! You skipped me.",
				"services1": "Oops, you skipped me! Please select one.",
				"media1": "Oops, you skipped me! Please select one.",
			},
			submitHandler: function (form){
				var postData = $(form).serializeArray();
				postData.push({name: 'hostname', value: document.location.hostname});
				postData.push({name: 'utm', value: getUTM()});

				// Google reCAPTCHA
				grecaptcha.ready(function(){
					grecaptcha.execute('6LcBVd0ZAAAAAGDKgIiV-0UwUgaf1kEXfcsYh6dK', {action: 'jotformSubmit'}).then(function (token) {
						postData.push({name: 'token', value: token});
						postData.push({name: 'action', value: 'jotformSubmit'});
						//console.log(postData);
						$.ajax({
							type: "POST",
							url: "../jotform-contact-us.php",
							data: postData,
							cache: false
						}).done(function (data) {
							//console.log("ajax returned: "+data);
							$(".rsd-contact input[type=checkbox]").prop( "checked", false );
							$(".rsd-contact select").prop("selectedIndex", 0);
							$(".rsd-contact input[type=text], .rsd-contact textarea").val("");
							showDesiredStep();

							// GTM: dataLayer Push
							var formRegarding = '';
							postData.forEach(function(row){
								if (row.name == 'regarding'){
									formRegarding = row.value.toLowerCase();
								}
							});
							pushGTM('contact-form-submit',{
								'action' : 'form-submit',
								'regarding' : formRegarding,
							});
						}).fail(function (xhr, textStatus, errorThrown) {
							//console.log("ajax failure: " + textStatus + "|" + errorThrown);
						});
					});
				});
			}
		});

		if (_form.valid() == true) {
			//on ajax success?
			if (_nextStep === "6") {
				//makes the form submit so we can get into submithandler
				_form.submit();
			} else {
				showDesiredStep();
			}
		}
	}

	function showDesiredStep() {
		//Removes active class from pagination
		$(".rsd-contact-footer-pagination-bullet").removeClass("active");

		//Hides current step
		$(_currentStep).fadeOut(function () {
			//Shows current step
			$(".rsd-contact-step-" + _nextStep).fadeIn();
		});

		//Scroll to top contact module
		$root.animate({
			scrollTop: $(".rsd-contact").offset().top - 64
		}, 300);
	}

	// Pulls UTM data from URL or Session Storage, returns JSON
	function getUTM(){
		// Pulls all utm_ from session storage
		var utmSession = {};    
		for (var i = 0; i < sessionStorage.length; i++) {
			var key = sessionStorage.key(i);        
			if (key && key.indexOf("utm_") === 0) {
			utmSession[key] = sessionStorage.getItem(key);
			}
		}	

		// Pulls all utm_ from query string
		var utmQuery = {};
		var queryString = window.location.search.substring(1);
		var urlParams = queryString.split("&");
		for (var i = 0; i < urlParams.length; i++) {
			var param = urlParams[i].split("=");
			var key = decodeURIComponent(param[0]);
			var value = param.length > 1 ? decodeURIComponent(param[1]) : "";
			if (key.indexOf("utm_") === 0) {
				utmQuery[key] = value;
			}
		}
	
		// Returns JSON of UTM variables
		if (Object.keys(utmSession).length !== 0){
			return JSON.stringify(utmSession);			
		} else if (Object.keys(utmQuery).length !== 0){
			return JSON.stringify(utmQuery);
		} else {
			return null;
		}
	}

});



// ===================================================================
// New Site modal
// ===================================================================
function getURLParameter(name) {
	var urlParams = new URLSearchParams(window.location.search);
	return urlParams.get(name);
 }
 var utmSource = getURLParameter('utm_source');
 if (utmSource !== null) {
	$('#modal-newsite').modal();
 }



// ===================================================================
// Global Function to event and properties object to GTM dataLayer
// ===================================================================
function pushGTM(eventName,eventProperties){
	if (typeof window.dataLayer !== 'undefined'){
		if (typeof eventName === 'string' && typeof eventProperties === 'object'){
			eventName = eventName.replace(' ','-').replace('_','-');
			window.dataLayer.push({
				'event': 'JS - '+eventName,
				'js-event': eventProperties
			});
		}
	}
}